import { Footer } from '@vivino/js-web-common';
import React from 'react';
import ReactDOM from 'react-dom';
import Page from 'vivino-js/utilities/Page';

function render() {
  const footerElement = document.getElementById('footer-container');
  const userNotification = window.__PRELOADED_USER_NOTIFICATION__;
  if (userNotification) {
    const urlWithoutParameter = location.href.replace(
      `user_notification_type=${userNotification}`,
      ''
    );
    history.replaceState(null, null, urlWithoutParameter);
  }

  footerElement &&
    ReactDOM.render(
      <Page>
        <Footer countryCode={window.__PRELOADED_COUNTRY_CODE__} />
      </Page>,
      footerElement
    );
}

render();

export { render };
